var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.permissions.includes('novo_modelo'))?_c('b-button',{staticClass:"mb-2",attrs:{"variant":"success"},on:{"click":function($event){return _vm.$router.push('/sistemicos/modelo/novo')}}},[_vm._v("Novo Modelo "),_c('fa-icon',{attrs:{"icon":"plus"}})],1):_vm._e(),_c('Card',{staticClass:"mb-2 pb-3",scopedSlots:_vm._u([{key:"card-body",fn:function(){return [_c('b-row',{staticClass:"pt-3"},[_c('b-col',[_c('label',[_vm._v("Canal")]),_c('b-form-select',{attrs:{"options":_vm.selectOptionsChannels},model:{value:(_vm.selected.channel_id),callback:function ($$v) {_vm.$set(_vm.selected, "channel_id", $$v)},expression:"selected.channel_id"}})],1),_c('b-col',{attrs:{"md":"2"}},[_c('FormSelect',{attrs:{"title":'Aplicação:',"options":_vm.applicationSelectOptions},model:{value:(_vm.selected.application_id),callback:function ($$v) {_vm.$set(_vm.selected, "application_id", $$v)},expression:"selected.application_id"}})],1),_c('b-col',{attrs:{"md":"2"}},[_c('FormSelect',{attrs:{"title":'Ativo/Inativo',"options":[
              { description: 'Todos', value: '' },
              { description: 'Somente Ativos', value: '1' },
              { description: 'Somente Inativos', value: '0' },
            ]},model:{value:(_vm.selected.active),callback:function ($$v) {_vm.$set(_vm.selected, "active", $$v)},expression:"selected.active"}})],1),_c('b-col',{attrs:{"md":"2"}},[_c('FormSelect',{attrs:{"title":'Legados/Não Legados',"options":[
              { description: 'Todos', value: '' },
              { description: 'Somente Legados', value: '1' },
              { description: 'Somente Não Legados', value: '0' },
            ]},model:{value:(_vm.selected.legacy),callback:function ($$v) {_vm.$set(_vm.selected, "legacy", $$v)},expression:"selected.legacy"}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('FormSelect',{attrs:{"title":'Tipo de Notificação',"options":_vm.notificationSelectOptions},model:{value:(_vm.selected.notification_type_id),callback:function ($$v) {_vm.$set(_vm.selected, "notification_type_id", $$v)},expression:"selected.notification_type_id"}})],1)],1)]},proxy:true}])}),_c('Card',{scopedSlots:_vm._u([{key:"card-body",fn:function(){return [_c('TableTemplatesList',{attrs:{"templates":_vm.templates}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }